




























































import { User } from '@/classes/TayehApi';
import { adminValidateUser, changeUserReferralCode, ResponseUser } from '@/classes/TayehApi/User';
import Pagination from '@/components/Pagination.vue';
import { Vue, Component } from 'vue-property-decorator';
import CopyToClipboard from 'vue-copy-to-clipboard'
@Component({
    components: {
        Pagination,
        CopyToClipboard
    }
})
export default class Users extends Vue {
    page = 0;
    count = 0;
    search: string = null;
    users: ResponseUser[] = [];
    new_referral_code: string = null;
    referral_limit = 5;
    user_id: string = null;
    show_change_referral = false;
    show_user_access_form = false;
    user_access_model = {
        userId: null as string,
        password: '',
        emailVerified: true,
        mobileVerified: true
    }

    inviteCopied (invite_code: string) {
        alert('کد دعوت کپی شد. '+invite_code)
    }
    pageChange (page: number) {
        this.page = page;
        this.getUsers();
    }

    async getUsers () {
        const res = await User.getUsers({
            page: this.page,
            per_page: 32,
            text: this.search
        });
        this.count = res.data.count;
        this.users = res.data.users;
        // this.data = res.data;
    }

    async setReferralCode () {
        try {
            let res = await changeUserReferralCode(this.user_id, this.new_referral_code, this.referral_limit)
        } catch (err) {
            console.log(err);
            
        }
    }

    adminValidateUser () {
        adminValidateUser(this.user_access_model)
    }

    public get tableUsers (): ResponseUser[] {
        return this.users;
    }

    ban () {
        console.log('banning');
        
    }

    mounted() {
        this.getUsers()
    }
}
