import { Cirus } from "@/classes/Http";

export class CreateUserSupportTicketBody {
    title: string;
    category_id: number;
    message: string;
    instance?: number;
}

export function createSupportTicket(body: CreateUserSupportTicketBody) {
    const query = '/admin/support/ticket';
    return Cirus.put(query, body);
}