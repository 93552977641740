import { Cirus } from "@/classes/Http";

export class CreateUserSupportTicketMessageRequest {
    message: string;
    file_id: string;
}

export function answerTicket(id: number, message: CreateUserSupportTicketMessageRequest) {
    const query = `/admin/support/ticket/${id}/message`;
    return Cirus.put(query, message);
}
