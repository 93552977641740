import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";
import { objectToQuery } from "../../ObjectToQuery";

export class Image {
    id?: string;
    url: string;
    alt: string;
}

enum Sex {
    Male = "male",
    Female = "female",
    Unknown = "na"
}

export class ResponseInstance {
    id: number;
    name: string;
    username: string;
    description: string;
    address: string;
    mobile: string;
    phone: string;
    email: string;
    logo: Image;
    logo_id: string;
    category: string;
    dateCreated: Date;
}

export class GetInstancesResponse {
    count: number;
    instances: ResponseInstance[];
}

class Params {
    search?: string;
    page?: number;
    per_page?: number;
}

export function getInstances(params: Params): Promise<AxiosResponse<GetInstancesResponse>> {
    const query = `/instance/all?${objectToQuery(params)}`;
    return Cirus.get(query);
}