import { Cirus } from "@/classes/Http";

export function getMediaUrl (media_id: string) {
    const query = `/media/${media_id}/url`;
    return Cirus.get(query);
}


export function uploadImage (file: any) {
    return Cirus.upload_image(file);
}

export function uploadAvatar (file: any) {
    return Cirus.upload_avatar(file);
}

export function uploadBanner (file: any) {
    return Cirus.upload_image(file);
}