






























import { Instance } from '@/classes/TayehApi';
import { ResponseInstance } from '@/classes/TayehApi/Instance';
import Pagination from '@/components/Pagination.vue';
import { Vue, Component } from 'vue-property-decorator';
@Component({
    components: {
        Pagination
    }
})
export default class Instances extends Vue {
    search: string = null;
    page = 0;
    count = 0;
    instances: ResponseInstance[] = [];

    pageChange (page: number) {
        console.log('page',page);
        
        this.page = page;
        this.getInstances();
    }

    async getInstances () {
        const res = await Instance.getInstances({
            search: this.search,
            page: this.page,
            per_page: 20
        });
        this.count = res.data.count;
        this.instances = res.data.instances;
        // this.data = res.data;
    }

    public get tableInstances (): ResponseInstance[] {
        return this.instances;
    }

    ban () {
        console.log('banning');
    }

    mounted() {
        this.getInstances()
    }
}
