import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";

export class GetUserSupportTicketCategoryResponse {
    id!: number;
    name!: string;
}

export function getSupportTicketCategories(): Promise<AxiosResponse<GetUserSupportTicketCategoryResponse[]>> {
    const query = '/admin/support/categories';
    return Cirus.get(query);
}