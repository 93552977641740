import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";

enum TicketStatusName {
    waiting_for_support='در انتظار پشتیبانی',
    support_answered='پاسخ پشتیبانی',
    user_answered='پاسخ کاربر',
    closed='بسته شده'
}

enum TicketStatusCode {
    waiting_for_support=0,
    support_answered=1,
    user_answered=2,
    closed=3
}

class TicketStatus {
    status_code: TicketStatusCode;
    status_name: TicketStatusName;
}

class TicketUser {
    avatar: Image;
    name: string;
}

class SupportUser extends TicketUser {
    role: string;
}

enum SenderType {
    user='user',
    support='support'
}

class Image {
    id?: string;
    url: string;
    alt: string;
}

export class SupportTicketMessage {
    message: string;
    sender_type: SenderType;
    file: Image;
    user: SupportUser = null;
    dateSent: Date;
}

export class GetSupportTicketResponse {
    id: number;
    title: string;
    messages: SupportTicketMessage[];
    creator: TicketUser;
    support: SupportUser
    dateCreated: Date;
    dateUpdated: Date;
    closed: boolean;
    status: TicketStatus;
}


export function getSupportTicket(id: number): Promise<AxiosResponse<GetSupportTicketResponse>> {
    const query = `/admin/support/ticket/${id}`;
    return Cirus.get(query);
}
