import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";
import { objectToQuery } from "../../ObjectToQuery";

export class Image {
    id?: string;
    url: string;
    alt: string;
}

enum Sex {
    Male = "male",
    Female = "female",
    Unknown = "na"
}

export class ResponseUser {
    name: string;
    email: string;
    mobile: string;
    username: string;
    invite_code: string;
    avatar: Image;
    blocked: boolean;
    sex: Sex;
    credit: number;
    dateCreated: Date;
    dateDeleted: Date;
}

export class GetUsersResponse {
    count: number;
    users: ResponseUser[];
}

class GetUsersParams {
    text?: string;
    page?: number;
    per_page?: number;
}

export function getUsers(params?: GetUsersParams): Promise<AxiosResponse<GetUsersResponse>> {
    const query = `/user/find/all?${objectToQuery(params)}`;
    return Cirus.get(query);
}