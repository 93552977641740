import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";
import { objectToQuery } from "../ObjectToQuery";

enum TicketStatus {
    waiting_for_support='در انتظار پشتیبانی',
    support_answered='پاسخ پشتیبانی',
    user_answered='پاسخ کاربر',
    closed='بسته شده'
}

enum TicketStatusCode {
    waiting_for_support=0,
    support_answered=1,
    user_answered=2,
    closed=3
}

class SupportTicket {
    id!: number;
    title!: string;
    category!: string;
    status!: TicketStatus;
    status_code!: TicketStatusCode;
    dateUpdated!: Date;
}

export class GetTicketsResponse {
    count!: number;
    tickets!: SupportTicket[];
}

export function getTickets(page = 0, search = ''): Promise<AxiosResponse<GetTicketsResponse>> {
    const query = `/admin/support/tickets?${objectToQuery({page, search})}`;
    return Cirus.get(query);
}