import { Cirus } from "@/classes/Http";
import { AxiosResponse } from "axios";
import { objectToQuery } from "../ObjectToQuery";

export function getUserMe() {
    return Cirus.get(`/me`, {});
}

export function getPrimary() {
    return Cirus.get(`/user/instance/primary`, {});
}

export function setPrimary(instance_id: number) {
    const query = `/user/instance/primary?instance_id=${instance_id}`;
    return Cirus.post(query, {}, {});
}

export class UserShop {
    id!: number;
    name!: string;
    username!: string;
    accepted!: number;
}

export class GetUserShopInstancesResponse {
    instances!: UserShop[];
    count!: number;
}

export function getInstances(params: string): Promise<AxiosResponse<GetUserShopInstancesResponse>> {
    const query = `/user/instances/?${params}`;
    return Cirus.get(query, {});
}

const scopeToDay  = {
    'day': 1,
    'week': 7,
    'month': 30,
    'year': 365
}
type Scope = 'day'|'week'|'month'|'year';
class getUserShopsTotalStatResponse{
    total_growth!: string;
    total_sold!: number;
    total_bought!: number;
}
export function getUserShopsTotalStat(time_scope: Scope, time_scale: number): Promise<AxiosResponse<getUserShopsTotalStatResponse>> {
    const days = scopeToDay[time_scope]*time_scale;
    const query = `/user/shops/stats?days=${days}`;
    return Cirus.get(query, {});
}

export function getTayehInvoices() {
    const query = `/user/customer/invoices`;
    return Cirus.get(query, {});
}

export * from './Query/'
export * from './Command/'